{
  "navbar": {
    "home": "Accueil",
    "photos": "Photos",
    "contact": "Contact"
  },
  "header": {
    "fr-flag-alt": "Drapeau français",
    "fr-flag-disabled-alt": "Drapeau français désactivé",
    "fr-flag-title": "Set site language to french",
    "en-flag-alt": "Drapeau anglais",
    "en-flag-disabled-alt": "Drapeau anglais désactivé",
    "en-flag-title": "Set site language to english"
  },
  "footer": {
    "made-by": "Réalisé par ",
    "and": "et",
    "legal-mentions": "Mentions légales"
  },
  "modal-refresh": {
    "title": "Nouvelle version",
    "details": "Une nouvelle version est disponible",
    "question": "Voulez-vous recharger la page maintenant ?",
    "close": "Fermer",
    "refresh": "Recharger"
  },
  "pictures": {
    "all": "Toutes les photos",
    "types": {
      "home": "Maison",
      "garden": "Jardin",
      "surrondings": "Alentours"
    }
  },
  "contact": {
    "title": "Demande d'informations",
    "form": "Formulaire de contact",
    "form-name": "Nom",
    "form-name-placeholder": "Entrez votre nom",
    "form-email": "Email",
    "form-email-placeholder": "Entrez votre adresse mail",
    "form-message": "Message",
    "form-message-placeholder": "Entrez votre message",
    "form-send": "Envoyer",
    "form-required": "Ce champs est obligatoire",
    "form-incorrect-mail": "L'adresse mail fournie est incorrecte",
    "message-sent": "Votre message a bien été envoyé."
  },
  "construction": "Cette page est en cours de construction"
}