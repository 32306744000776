<template>
  <header>
    <div class="header-menu">
      <router-link to="/" exact class="header-menu-link">{{ $t('navbar.home') }}</router-link>
      <router-link to="/photos" class="header-menu-link">{{ $t('navbar.photos') }}</router-link>
      <router-link to="/contact" class="header-menu-link">{{ $t('navbar.contact') }}</router-link>
    </div>
    <div class="header-flags">
      <img v-if="locale === 'fr'" src="@/assets/images/flags/fr.png" :alt="$t('header.fr-flag-alt')">
      <img v-else src="@/assets/images/flags/fr-disabled.png" 
        :alt="$t('header.fr-flag-disabled-alt')" 
        @click="updateLocale('fr')" 
        class="header-img-disabled"
        :title="$t('header.fr-flag-title')">

      <img v-if="locale === 'en'" src="@/assets/images/flags/en.png" :alt="$t('header.en-flag-alt')">
      <img v-else src="@/assets/images/flags/en-disabled.png"
        :alt="$t('header.en-flag-disabled-alt')"
        @click="updateLocale('en')"
        class="header-img-disabled"
        :title="$t('header.en-flag-title')">
    </div>
  </header>
  <div class="app">
    <router-view v-slot="{ Component }">
      <transition name="router-slide" mode="out-in" appear>
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <footer>
    <span>
      {{ $t("footer.made-by") }} <a href="https://dumortierweb.fr" target="blank">Julien Dumortier</a>
      {{ $t("footer.and") }} <a href="https://anthony.wispelaere.fr/" target="blank">Anthony Wispelaere</a>
    </span>
    <span class="footer-legal-mentions">- <a href="./documents/legal_mentions.pdf" target="blank">{{ $t('footer.legal-mentions') }}</a></span>
  </footer>
  <modal-refresh v-if="hasNewContent" @close="hasNewContent = false" @refresh="refreshApp" />
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Settings } from 'luxon'

export default {
  setup() {
    /* --------------- Init app ---------------- */
    const hasNewContent = ref(false)
    const refreshing = ref(false)
    const newRegistration = ref(null)

    const showRefreshUI = e => {
      newRegistration.value = e.detail
      hasNewContent.value = true
    }

    const refreshApp = () => {
      hasNewContent.value = false
      newRegistration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
    }

    document.addEventListener('swUpdated', showRefreshUI, { once: false })
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing.value) return
        //eslint-disable-next-line no-console
        console.info('[Service worker] refreshing app')
        refreshing.value = true
        window.location.reload()
      })
    }
    /* ------------ End of init app ------------ */
    let locale = ref(useI18n({ useScope: 'global' }).locale)

    const updateLocale = newLocale => {
      locale.value = newLocale
      Settings.defaultLocale = newLocale
    }

    return { hasNewContent, refreshApp, locale, updateLocale }
  }
}
</script>

<style lang="scss">
  header {
    height: 2rem;
    width: calc(100% - 2rem);
    background-color: var(--main-color);
    color: var(--contrast-color);
    line-height: 2rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;

    .header-menu {
      display: flex;
      column-gap: 1rem;

      &-link {
        color: var(--constrast-color);
        text-decoration: none;

        &.router-link-active, &:hover {
          border-bottom: 1px solid var(--contrast-color);
        }
      }
    }

    .header-flags {
      height: 100%;

      img {
        height: 60%;
        margin-left: 1rem;

        &.header-img-disabled {
          cursor: pointer;
        }
      }
    }
  }
  .app {
    height: calc(100% - 6rem);
    overflow-x: hidden;

    .router-slide-enter-from {
      opacity: 0;
      transform: translateX(100px);
    }

    .router-slide-enter-active {
      transition: all 0.3s ease-out;
    }

    .router-slide-leave-to {
      opacity: 0;
      transform: translateX(-100px);
    }

    .router-slide-leave-active {
      transition: all 0.3s ease-in;
    }
  }

  footer {
    background-color: var(--main-color);
    color: var(--contrast-color);
    height: 2rem;
    line-height: 2rem;
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;

    a, a:visited, a:hover, a:active, a:focus, a:target {
      color: var(--contrast-color);
    }

    .footer-legal-mentions {
      margin-left: 0.2rem;
    }
  }

  @include mobile {
    footer {
      font-size: 0.9rem;
      line-height: 1rem;
      flex-direction: column;

      span { text-align: center;}
    }
  }
</style>
